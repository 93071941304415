import CategoryLink from '@jetshop/ui/CategoryLink';
import React, { useState, useEffect } from 'react';
import {
  useRouteMatch,
  matchPath,
  useLocation,
  useHistory
} from 'react-router';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import UIMaxWidth from '../../MaxWidth';
import SubMenuSection from './SubMenuSection';
import AnimateHeight from 'react-animate-height';
import { css } from 'linaria';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import avgas from '../../../../assets/images/del-avgas.jpg';
import backspegel from '../../../../assets/images/del-backspegel.jpg';
import bakvagn from '../../../../assets/images/del-bakvagn.jpg';
import belysning from '../../../../assets/images/del-belysning.jpg';
import bromsar from '../../../../assets/images/del-bromsar.jpg';
import bransle from '../../../../assets/images/del-bransle.jpg';
import drivaxel from '../../../../assets/images/del-drivaxel.jpg';
import dorrar from '../../../../assets/images/del-dorrar.jpg';
import framvagn from '../../../../assets/images/del-framvagn.jpg';
import glasrutor from '../../../../assets/images/del-glasrutor.jpg';
import inredning from '../../../../assets/images/del-inredning.jpg';
import instrument from '../../../../assets/images/del-instrument.jpg';
import kaross from '../../../../assets/images/del-kaross.jpg';
import karossbak from '../../../../assets/images/del-karossbak.jpg';
import karossfram from '../../../../assets/images/del-karossfram.jpg';
import karosslister from '../../../../assets/images/del-karossfram.jpg';
import kylsystem from '../../../../assets/images/del-kylsystem.jpg';
import kylsystem2 from '../../../../assets/images/del-kylsystem.jpg';
import las from '../../../../assets/images/del-las.jpg';
import motordetaljer from '../../../../assets/images/del-motordetaljer.jpg';
import motor from '../../../../assets/images/del-motor.jpg';
import rela from '../../../../assets/images/del-rela.jpg';
import spolare from '../../../../assets/images/del-spolare.jpg';
import stromstallare from '../../../../assets/images/del-stromstallare.jpg';
import styrboxar from '../../../../assets/images/del-styrboxar.jpg';
import styrning from '../../../../assets/images/del-stryning.jpg';
import utrustning from '../../../../assets/images/del-utrustning.jpg';
import vaxellador from '../../../../assets/images/del-vaxellador.jpg';
import vaxelladsdetaljer from '../../../../assets/images/del-vaxelladsdetaljer.jpg';
import sakerhetsdetaljer from '../../../../assets/images/del-sakerhetsdetaljer.jpg';
import el from '../../../../assets/images/del-el.jpg';
import radio from '../../../../assets/images/del-radio.jpg';
import partscar from '../../../../assets/images/parts-car.jpg';
import { ReactComponent as SearchIcon } from '../../../../svg/SearchAlt.svg';

// Limit the menu to our global MaxWidth
const MaxWidth = styled('div')`
  min-height: 320px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;

  a {
    display: block;
  }

  ${theme.below.lg} {
    flex-wrap: wrap;
  }
`;

const InnerWrapper = styled('div')`
  width: 100%;
`;

const Wrapper = styled('section')`
  position: absolute;
  right: 0;
  left: 0;
  overflow: hidden;
  z-index: 999;
  will-change: height;
  transition: height 0.3s ease-in-out;
  width: 100%;
`;

const menuItem = css`
  a {
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 1.125rem;
    color: ${theme.colors.black};
    margin-top: 20px;
  }
`;

const menuItem2 = css`
  a {
    font-size: 1rem !important;
    font-weight: 400 !important;
  }

  button {
    color: rebeccapurple !important;
  }
`;

const ContentWrapper = styled('div')`
  ${theme.below.lg} {
    margin-left: 10px;
  }
`;

const Level2Style = css`
  ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    margin-left: 40px;

    li {
      margin-top: 5px;
      margin-bottom: 0.3rem;
      margin-right: 30px;

      a {
        color: #1a202d;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 28px;
        text-decoration: none;
        display: flex;
        align-items: center;
        margin-top: 0px;
        :hover {
          text-decoration: underline;
        }
        svg {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
      &.active {
        a {
          color: #1a202d;
        }
      }
    }
  }
`;

const Level3Style = css`
  ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;

    &.two-columns {
      li {
        margin-right: 4rem;
      }
    }

    li {
      margin-bottom: 0.3rem;
      a {
        color: ${theme.colors.black};
        text-decoration: none;
        :hover {
          text-decoration: underline;
        }
      }
      &.active {
        a {
          color: ${theme.colors.black};
        }
      }
    }
  }
`;

const CategoryName = styled.div`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    background-color: ${theme.colors.white};
    width: 5px;
    height: 5px;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const CategorySubName = styled('div')``;

const SubMenuContentSection = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  position: relative;
`;

const InputField = styled('div')`
  display: flex;
  align-items: center;
  width: 70%;
  height: 50px;
  background-color: #ffffff;
  border: 0.5px solid #c4c5c5;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const StyledInput = styled('input')`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  padding-left: 16px;
  font-family: 'Barlow';
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 19.6px;
  letter-spacing: 1px;
  color: #1a202d;
  outline: none;
`;

const SearchIconContainer = styled('div')`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`;

const thirdMenuItem = css`
  font-size: 1rem !important;
`;

const showMoreButton = css`
  margin-top: 10px;
  line-height: 22.4px;
  font-size: 1rem;
  font-weight: 700;
`;

const ShowAllCategoriesButton = styled('button')`
  color: white !important;
  background: #1a202d !important;
  font-size: 1rem;
  font-family: 'Barlow';
  font-weight: 700;
  text-transform: uppercase;
  line-height: 25.6px;
  word-wrap: break-word;
  background-color: #1a202d;
  padding: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 32px;
`;

const carPartImageStyles = css`
  width: 150px;
  margin-top: 20px;
`;

const submitButtonStyles = css`
  height: 50px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6cc400 !important;
  color: white !important;
  text-transform: uppercase;
  font-family: 'Barlow';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.42px;
`;

const uniqueUlStyles = css`
  margin-left: 0px !important;
`;

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeSubcategory, setActiveSubcategory] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const handleSubmit = e => {
    e.preventDefault();

    const url = `/search/?term=${encodeURIComponent(searchQuery)}`;

    closeNav();

    history.push(url);
  };

  const subcategoryImages = [
    kylsystem,
    avgas,
    backspegel,
    bakvagn,
    belysning,
    glasrutor,
    bromsar,
    bransle,
    partscar,
    drivaxel,
    dorrar,
    el,
    framvagn,
    inredning,
    instrument,
    kaross,
    karossbak,
    karossfram,
    karosslister,
    kylsystem2,
    las,
    motordetaljer,
    motor,
    radio,
    rela,
    spolare,
    stromstallare,
    styrboxar,
    styrning,
    sakerhetsdetaljer,
    utrustning,
    vaxellador,
    vaxelladsdetaljer
  ];

  useEffect(() => {
    let subCategoryInRoute = null;

    if (
      activeCategory &&
      activeCategory?.subcategories &&
      activeCategory?.subcategories?.length > 0
    ) {
      subCategoryInRoute = activeCategory?.subcategories?.find(cat => {
        return matchPath(location.pathname, {
          path: cat?.primaryRoute?.path,
          strict: false,
          sensitive: false
        });
      });
    }

    setActiveSubcategory(subCategoryInRoute);
  }, [activeCategory, location.pathname]);

  return (
    <Wrapper>
      <AnimateHeight height={pose === 'open' ? 'auto' : 0} duration={350}>
        <MaxWidth>
          <ContentWrapper>
            <form onSubmit={handleSubmit}>
              <SubMenuContentSection>
                <InputField>
                  <StyledInput
                    type="text"
                    placeholder="Sök på reservdel, bilmärke eller modell"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                  />
                  <SearchIconContainer>
                    <SearchIcon />
                  </SearchIconContainer>
                </InputField>
                <button type="submit" className={submitButtonStyles}>
                  Sök
                </button>
              </SubMenuContentSection>
            </form>
            <div className={Level2Style}>
              <ul>
                {activeCategory &&
                  activeCategory?.subcategories
                    ?.slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((cat, index) => {
                      return (
                        <Level2Item
                          key={cat.id}
                          cat={cat}
                          activeSubcategory={activeSubcategory}
                          setActiveSubcategory={setActiveSubcategory}
                          closeNav={closeNav}
                          image={subcategoryImages[index]}
                        />
                      );
                    })}
              </ul>
            </div>
            <Link to="/bil" onClick={closeNav}>
              <ShowAllCategoriesButton>
                Se alla produkter
              </ShowAllCategoriesButton>
            </Link>
            {/* Save this code below... */}
            {/* <div className={Level3Style}>
              {activeSubcategory?.subcategories?.length > 0 && (
                <ul
                  className={
                    activeSubcategory?.subcategories?.length > 8
                      ? 'two-columns'
                      : ''
                  }
                >
                  {activeSubcategory.subcategories.map(cat => {
                    return (
                      <Level3Item key={cat.id} cat={cat} closeNav={closeNav} />
                    );
                  })}
                </ul>
              )}
            </div> */}
          </ContentWrapper>
        </MaxWidth>
      </AnimateHeight>
    </Wrapper>
  );
};

const Level2Item = ({
  cat,
  activeSubcategory,
  setActiveSubcategory,
  closeNav,
  image
}) => {
  const thirdCategory = cat?.subcategories?.map(item => {
    return item;
  });

  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const toggleText = showAll ? 'Visa mindre' : 'Visa fler kategorier';

  const buttonTextColor = showAll ? '#6CC400' : '#1A202D';

  const displayedThirdCategory = showAll
    ? thirdCategory
    : thirdCategory?.slice(0, 3);

  const isMatch = useRouteMatch({
    path: cat?.primaryRoute?.path,
    strict: false,
    sensitive: false
  });
  return cat?.subcategories?.length > 0 ? (
    <li
      key={cat.id}
      className={`
        ${menuItem} 
        ${
          (activeSubcategory && activeSubcategory.id === cat.id) || isMatch
            ? 'active'
            : ''
        }`}
      onMouseEnter={() => setActiveSubcategory(cat)}
    >
      <img src={image} alt={cat?.name} className={carPartImageStyles} />
      <CategoryLink onClick={closeNav} category={cat}>
        {cat?.name}
      </CategoryLink>
      <ul className={uniqueUlStyles}>
        {displayedThirdCategory?.map(item => (
          <li className={menuItem2}>
            <a href={item?.primaryRoute?.path}>{item?.name}</a>
          </li>
        ))}
      </ul>
      {thirdCategory?.length > 5 && (
        <button
          onClick={toggleShowAll}
          className={showMoreButton}
          style={{ color: buttonTextColor }}
        >
          {toggleText}
        </button>
      )}
    </li>
  ) : (
    <li key={cat.id} className={menuItem}>
      <CategoryLink onClick={closeNav} category={cat}>
        {cat?.name}
      </CategoryLink>
      {thirdCategory?.map(item => {
        return (
          <li>
            <a href={item?.primaryRoute?.path} className={thirdMenuItem}>
              {item?.name}
            </a>
          </li>
        );
      })}
    </li>
  );
};

const Level3Item = ({ cat, closeNav }) => {
  const isMatch = useRouteMatch({
    path: cat.primaryRoute.path,
    strict: false,
    sensitive: false
  });
  const [name, subname] = cat.name.split(' - ');
  return (
    <li
      key={'level3' + cat.id}
      className={`${menuItem} ${isMatch ? 'active' : ''}`}
    >
      <CategoryLink onClick={closeNav} category={cat}>
        <CategoryName>{name}</CategoryName>
        {subname && <CategorySubName> {subname}</CategorySubName>}
      </CategoryLink>
    </li>
  );
};

export default SubMenuWrapper;
